import React, { Component, Fragment } from 'react'
import { RightOutlined } from '@ant-design/icons'
import { connect } from 'react-redux';
import {
  Link,
  withRouter
} from "react-router-dom";
import _ from 'lodash'
import { Avatar } from 'antd'
import { helpActions } from '../../_action/help.actions';
import { FAQDataContext } from '../../context'
import FAQCollectionItems from './Paginate/FAQCollectionItems';
import Pagination from './Paginate/Pagination';
const mapStateToProps = state => {
  return {
    FAQData: state.FAQData,
    FAQCategory: state.FAQCategory,
    searchQuery: state.searchQuery
  }
}

const mapDispatchToProps = dispatch => ({
  loadAllFAQs: () => dispatch(helpActions.loadAllFAQs()),
  getFAQCategory: () => dispatch(helpActions.getFAQCategory())
})

export class FAQCollectionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      itemPerPage: 5,
    }
  }

  componentDidMount() {
    this.props.loadAllFAQs();
    this.props.getFAQCategory()
  }

  render() {
    const FAQCategotyData = _.groupBy(this.props.FAQData, item => item.faqCategoryId);
    const FAQCollections = Object.values(FAQCategotyData);
    const FAQCollectByCategoryId = FAQCollections.filter(item => item[0].faqCategoryId === this.props.match.params.faqCategoryId);
    if (!FAQCollectByCategoryId) {
      return null;
    }
    const collectionContext = FAQDataContext.filter(collectionItem => collectionItem.faqCategoryId === this.props.match.params.faqCategoryId)
    const { currentPage, itemPerPage } = this.state;
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOFFirstItem = indexOfLastItem - itemPerPage;
    const currentItem = _.slice(FAQCollectByCategoryId[0], indexOFFirstItem, indexOfLastItem);
    const paginate = pageNumber => this.setState({
      currentPage: pageNumber
    })
    if (!FAQCollectByCategoryId[0]) {
      return null
    }
    const { FAQCategory } = this.props;
    const FAQCollection = _.filter(FAQCategory, item => item.id === this.props.match.params.faqCategoryId)
    if (!FAQCollection[0]) {
      return null
    }

    return (
      <div className="faq-collection-list">
        <Fragment>
          <div className="faq-backlink">
            <Link to="/"> Tất cả câu hỏi </Link>
            <RightOutlined />
            <span>{FAQCollection[0].title} </span>
          </div>
          <div className="FAQCollectionList">
            <div className="FAQCollectionList-header">
              <div className="FAQCollection-items" >
                <div className="FAQCollection-icon">
                  <img src={FAQCollection[0].image} />
                </div>
                <div className="FAQCollection-content">
                  <div className="FAQCollection-title">
                    {FAQCollection[0].title}
                  </div>
                  <div className="FAQCollection-description">
                    {FAQCollection[0].description ? FAQCollection[0].description : null}
                  </div>
                  <div className="FAQCollection-preview">
                    <ul className="FAQCollection-writer-avt">
                      <li><Avatar style={{ background: '#02B875', fontSize: '17px', fontWeight: '500' }}>+2</Avatar> </li>
                    </ul>
                    <div className="FAQCollection-about-writer">
                      <span> {FAQCollection[0].articleCount} bài viết</span>
                      <ul className="author-name">
                        Tác giả :
                        {collectionContext.length > 0 ? _.map(collectionContext[0].author, name => <li>{name} </li>) : ''}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="FAQItem-list">
              <FAQCollectionItems currentItem={currentItem} />
            </div>
          </div>
        </Fragment>
        {/* if num of page > 1 ( item > 5) show pagination else not show */}
        {
          FAQCollectByCategoryId[0].length > 5 ? <Pagination
            itemPerPage={itemPerPage}
            totalItem={FAQCollectByCategoryId[0].length}
            paginate={paginate} /> : ''
        }

      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FAQCollectionList))
