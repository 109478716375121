import _ from 'lodash'
import { Avatar} from 'antd'
import React, { Component, Fragment } from 'react'
import { Link } from "react-router-dom";
export class FAQCollectionItems extends Component {
  formatShortContent(content = '') {
    return content.replace(/(<\/?([^>])+>)|(&.+;)|(\w\.)|(  +)/g, ' ');
  }

  renderItem(faqItem) {
    return <Link key={faqItem._id} to={`/collections/category/${faqItem._id}`} >
      <div className="FAQItem">
        <div className="FAQItem-title">{faqItem.title}</div>
        <div className="FAQItem-description">
          { this.formatShortContent(faqItem.description || faqItem.content ) }
        </div>
        <div className="FAQItem-about-author">
          <div style={{ marginRight: '10px' }}>
            <Avatar src="/images/author-avt1.jpg" />
          </div>
          <div className="FAQItem-about-article">
            <span>Tác giả: <p>Lan Hương</p> </span>
            <p>Cập nhập hơn một tuần trước</p>
          </div>
        </div>
      </div>
    </Link>
  }
  render() {
    const {currentItem} = this.props;
    return (
      <Fragment>
        { 
          _.map(currentItem, faqItem => {
            return this.renderItem(faqItem)
          })
        }
      </Fragment>
    )
  }
}

export default FAQCollectionItems