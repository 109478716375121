const defaultState = {

}

const Reducer = (state = defaultState, action) => {
    switch(action.type) {
        case 'ALL_FAQS_LOADED':
            return {
                ...state,
                FAQData : action.payload,
            }
        case 'FAQ_CATEGORY_LOADED':
            return {
                ...state,
                FAQCategory: action.payload,
            }
        case 'SEARCH-QUERY-DATA':
            return {
                ...state,
                searchQuery: action.payload,
            }
        default: 
            return state;
    }
}

export default Reducer