import React, { Component } from 'react'
import { helpActions } from '../../_action/help.actions';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Avatar } from 'antd';
import { Link } from 'react-router-dom'
const mapStateToProps = state => {
  return {
    FAQData: state.FAQData,
    searchQuery: state.searchQuery
  }
}

const mapDispatchToProps = dispatch => ({
  load: () => dispatch(helpActions.load()),
  searchQuery: data => dispatch({
    type: 'SEARCH-QUERY-DATA',
    payload: data
})
})

export class SearchResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: ''
    }
  }
  componentDidMount() {
    // var url_string = window.location.href;
    // var url = new URL(url_string);
    // var key = url.searchParams.get('key');
    // this.setState({ key });
    this.props.load();
    // this.props.searchQuery(this.props.match.params)
  }
  removeVietNameseMark = (str) => {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    return str;
  }
  render() {
    const searchQuery = this.removeVietNameseMark(_.toLower(_.get(this.props.match.params, 'key')));
    const filterFAQData = _.filter(this.props.FAQData,
      item => _.includes(this.removeVietNameseMark(_.toLower(item.title)), searchQuery)
    );
    console.log('KIEN', searchQuery, this.props.FAQData, filterFAQData);
    return (
      <div className="search-result">
        <div style={{ display: 'flex', flexDirection: 'row', color: '#8F919D' }} className="search-title">
          <span>Kết quả tìm kiếm cho từ khóa :</span>
          <p style={{ color: '#5C6A76', marginLeft: '5px' }}> " {this.props.match.params.key} "</p>
        </div>

        <div className="FAQItem-list">
          {
            _.map(filterFAQData, FAQItem => (
              <Link key={FAQItem._id} to={`/collections/category/${FAQItem._id}`} >
                <div className="FAQItem">
                  <div className="FAQItem-title">{FAQItem.title}</div>
                  <div className="FAQItem-description">
                    { FAQItem.description || FAQItem.content.replace(/(<\/?([^>])+>)|(&.+;)|(\w\.)|(  +)/g, ' ') }
                  </div>
                  <div className="FAQItem-about-author">
                    <div style={{ marginRight: '10px' }}>
                      <Avatar src="/images/author-avt1.jpg" />
                    </div>
                    <div className="FAQItem-about-article">
                      <span>Tác giả: <p>Lan Hương</p> </span>
                      <p>Cập nhập hơn một tuần trước</p>
                    </div>
                  </div>
                </div>
              </Link>
            ))
          }
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResult)