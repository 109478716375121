import React, { Component, Fragment } from 'react'
import { Input } from 'antd';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

const mapStateToProps = state => {
}

const mapDispatchToProps = dispatch => ({
})

export class SearchFAQ extends Component {
    constructor(props) {
      super(props);
      this.state = {
        search: ''
      }
    }
    handleChange(event) {
      event.preventDefault();
      const value = event.target.value;
      this.setState({
        search: value,
      })
      // history.push(`/search/${value}`);
      // this.context.router.transitionTo('/');
      // this.props.history.push(`/search/${value}`);
      // this.props.doSearch(value);
    }

    render() {
      const { search } = this.state;
      return (
        <Fragment>
          <Input
            className="search-faqBox"
            placeholder=" Chúng tôi giúp được gì cho bạn?"
            name="search"
            onPressEnter={event => this.handleChange(event)}
          />
          {
            search ? <Redirect to={'/search/' + search} /> : null
          }
        </Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchFAQ)