import ActionButton from "antd/lib/modal/ActionButton";
import { helpServices } from "../services//help.services";
export const helpActions = {
    loadAllFAQs,
    getFAQCategory,
    load,
    searchQuery
}

function load() {
  return dispatch => {
    helpServices.getAllFAQs().then(res => {
      return  dispatch({ type: 'ALL_FAQS_LOADED', payload: res });
    })
    helpServices.getFAQCategory().then(res => {
      return  dispatch({ type: 'FAQ_CATEGORY_LOADED', payload: res });
    })
  }
}
function loadAllFAQs() {
    return dispatch => {
        helpServices.getAllFAQs()
        .then(res => {
            return  dispatch({ type: 'ALL_FAQS_LOADED', payload: res });
        })
       
    }
}

function getFAQCategory() {
    return dispatch => {
        helpServices.getFAQCategory()
        .then(res => {
            return  dispatch({ type: 'FAQ_CATEGORY_LOADED', payload: res });
        })
       
    }
}

function searchQuery(payload) {
    return {
        type: 'SEARCH-QUERY-DATA',
        payload
    }
}