import Search from 'antd/lib/input/Search'
import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom"
import SearchFAQ from './SearchFAQ';
import { Row, Col, Layout } from 'antd';
import 'antd/dist/antd.css';
import '../../style/header.scss';
import '../../style/HelpCenter.scss';
import '../../style/Section.scss';

export class Header extends Component {
  render() {
    return (
      <div className="header-faq">
        <div className='container'>
          <div className="brand-title">
            <div className="brand-title-logo" >
              <Link to="/" style={{ color: 'white' }}>
                <img src="/images/logo.svg" style={{ width: '30px', height: '30px', marginRight: '10px' }} />
              </Link>
              <p style={{ fontSize: '18px', fontWeight: '500' }}>
                <Link to="/" style={{ color: 'white' }}>ONCUSTOMER</Link>
              </p>
              <p className="hc-title" style={{ fontSize: '16px' }} > <span style={{ marginLeft: '5px' }}>|
                </span> <Link style={{ color: '#fff' }} to="/">  Trung Tâm Hỗ Trợ </Link> </p>
            </div>

            <div className="brand-title-link">
              <a href="https://oncustomer.asia/" style={{ color: 'white' }} target='_blank'>
                <img src="/images/LinkOutline.svg" className="Linkoutline-icon" />
                <span className="header-linkOutline" style={{ fontSize: '16px' }}> Truy cập Oncustomer </span>
              </a>
            </div>
          </div>
          
          <div className="greeting-title">
            <span> Chào mừng bạn đến với trang hỗ trợ của OnCustomer</span>
          </div>
          <div className="faq-search-box" >
            <SearchFAQ />
          </div>
          </div>
        </div>
    )
  }
}


export default Header