import React, { Component, Fragment } from 'react'
import { RightOutlined } from '@ant-design/icons'
import {
    BrowserRouter as Router,
    Link,
    withRouter
} from "react-router-dom";
import { connect } from 'react-redux'
import _ from 'lodash'
import Author from './Author';
import { helpActions } from '../../_action/help.actions';
import DangerousHTML from 'react-dangerous-html';

const mapStateToProps = state => {
  return {
    FAQData: state.FAQData,
    FAQCategory: state.FAQCategory,
    searchQuery: state.searchQuery
  }
}

const mapDispatchToProps = dispatch => ({
  load: () => {
    dispatch(helpActions.load());
  }
})

export class FAQDetail extends Component {
    componentDidMount() {
      this.props.load();
    }

    render() {
      const {FAQData, FAQCategory} = this.props;
      const { id } = this.props.match.params;
      const faqItemData = _.find(FAQData, item => item.id === id);
      const parentCollection = _.filter(FAQCategory, item => item.id === _.get(faqItemData, 'faqCategoryId'));
      if (_.isEmpty(faqItemData)) {
        return '';
      }
      return (
        <div className="faq-detail">
          <div className="faq-backlink">
            <Link to="/">Tất cả câu hỏi</Link>
            <RightOutlined />
            <Link to={`/collection/${_.get(_.first(parentCollection), 'id')}`}> {_.get(_.first(parentCollection), 'title')} </Link>
            <RightOutlined />
            <span> {_.get(faqItemData, 'title')} </span>
          </div>
          <div className="faq-content">
            <h1 className='faq-content-title'>{_.get(faqItemData, 'title')}</h1>
            <p className='faq-content-description'>{_.get(faqItemData, 'description')}</p>
            <Author />
            <DangerousHTML html={_.get(faqItemData, 'content')} />
          </div>
        </div>
      )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FAQDetail))
