import React, { Component, Fragment } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import  FAQDetail  from './FAQDetail';
import  FAQList  from './FAQList';
import  SearchResult  from '../Section/SearchResult';
import FAQCollectionList from './FAQCollectionList'
export class Section extends Component {
render() {
    return (
      <Fragment>
        <Switch>
          <Route path="/search/:key" component={SearchResult} />
          <Route exact path="/" component={FAQList} />
          <Route path="/collection/:faqCategoryId" component={FAQCollectionList} />
          <Route exact path="/collections/category/:id" component={FAQDetail} />
        </Switch>
      </Fragment>
    )
  }
}

export default Section