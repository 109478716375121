export const FAQDataContext = [
    {
        id: 1,
        faqCategoryId: "5ea28cbe2cf4c9005fb5be4d",
        title: "Oncustomer là gì?",
        description:'Đây là lần đầu bạn ghé thăm Oncustomer? Hãy xem cách Oncustomer có thể trợ giúp bạn từ việc bán hàng, Marketing cho tới chăm sóc khách hàng',
        icon: "/images/question-mark.svg",
        authorAvt: [
            "/images/author-avt2.jpg", "/images/author-avt1.jpg","/images/author-avt3.jpg"
        ],
        author: ["Trung Kiên", "Lan Hương", "Hằng Nguyễn", "và 2 người khác"]
    },
    {
        id:2,
        faqCategoryId: "5ea28cd42cf4c9005fb5be6e",
        title: "Khởi tạo và đăng nhập",
        description:'Chỉ với vài bước đơn giản bạn đã có thể bắt đầu sử dụng được Oncustomer',
        icon: "/images/add-user.svg",
        authorAvt: [
            "/images/author-avt1.jpg", "/images/author-avt2.jpg","/images/author-avt3.jpg"
        ],
        author: [ "Lan Hương", "Trung Kiên", "Hằng Nguyễn", "và 2 người khác"]
    },
    {
        id:3,
        faqCategoryId: "5ea28cea2cf4c9005fb5be9c",
        title: "Cài đặt hệ thống",
        description:'Hướng dẫn các bước cài đặt và tùy chỉnh ban đầu',
        icon: "/images/settings.svg",
        authorAvt: [
            "/images/author-avt1.jpg", "/images/author-avt2.jpg","/images/author-avt3.jpg"
        ],
        author: [ "Lan Hương", "Trung Kiên","Quốc Anh" , "và 2 người khác"]
    },
    {
        id:4,
        faqCategoryId: "5ea28cfc2cf4c9005fb5beaf",
        title: 'Quản lý tin nhắn',
        description:'Cài đặt và cá nhân hóa Messenger của bạn và thế là bạn có thể sử dụng Oncustomer để chăm sóc khách hàng đơn giản hiệu quả hơn',
        icon: "/images/mail.svg",
        authorAvt: [
            ,"/images/author-avt3.jpg","/images/author-avt1.jpg", "/images/author-avt2.jpg"
        ],
        author: ["Hằng Nguyễn","Trung Kiên", "Lan Hương",  "và 3 người khác"]
    },
    {
        id: 5,
        faqCategoryId: "5ea28d062cf4c9005fb5bebb",
        title: " Quản lý khách hàng",
        description:'Quản lý danh sách khách hàng hiệu quả hơn',
        icon: "/images/crm.svg",
        authorAvt: [
            "/images/author-avt1.jpg", "/images/author-avt2.jpg","/images/author-avt3.jpg"
        ],
        author: ["Trung Kiên", "Lan Hương", "Hằng Nguyễn", "và 2 người khác"]
    },
    {
        id: 6,
        faqCategoryId: "5ea28d392cf4c9005fb5bf13",
        title: "Câu hỏi thường gặp",
        description:'Câu hỏi thường gặp về Oncustomer',
        icon: "/images/question.svg",
        authorAvt: [
            "/images/author-avt2.jpg","/images/author-avt3.jpg", "/images/author-avt1.jpg"
        ],
        author: [ "Lan Hương", "Hằng Nguyễn","Trung Kiên", "và 2 người khác"]
    },
    {
        id:7,
        faqCategoryId: "5ea28d5e2cf4c9005fb5bf3f",
        title: "Kết nối đa kênh",
        description:'Tin nhắn của khách hàng được thu thập từ nhiều nguồn như Zalo, Facebook, Website... về ứng dụng để có thể quản lý khách hàng hiệu quả hơn',
        icon: "/images/computer.svg",
        authorAvt: [
            "/images/author-avt1.jpg", "/images/author-avt2.jpg","/images/author-avt3.jpg"
        ],
        author: ["Lan Hương", "Trung Kiên",  "Hằng Nguyễn","và 2 người khác"]
    },
    {
        id: 8,
        faqCategoryId: "5f4e0955ab321c0250357f61",
        title: "Cách quản lý hiệu quả",
        description:'Quản lý hiệu quả',
        icon: "/images/checklist.svg",
        authorAvt: [
            "/images/author-avt1.jpg", "/images/author-avt2.jpg","/images/author-avt3.jpg"
        ],
        author: [ "Lan Hương", "Trung Kiên","Hằng Nguyễn", "và 2 người khác"]
    },
    {
        id:9,
        faqCategoryId: "5f76e290c5bb6c01d84aa87d",
        title: "a a a",
        description:'new cate',
        icon: "/images/checklist.svg",
        authorAvt: [
            "/images/author-avt1.jpg", "/images/author-avt2.jpg","/images/author-avt3.jpg"
        ],
        author: [ "Lan Hương", "Trung Kiên","Hằng Nguyễn", "và 2 người khác"]
    }
]