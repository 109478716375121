import React from 'react';
import ReactDOM from 'react-dom';
import HelpCenter from './components/HelpCenter';
import { createStore, applyMiddleware  } from 'redux';
import { Provider } from "react-redux";
import thunk from 'redux-thunk';
import Reducer from './reducer/help';

import {
  // Router,
  BrowserRouter,
  Switch,
  Route,
  Link, NavLink,
  withRouter
} from "react-router-dom";

import { createBrowserHistory } from 'history'
export const history = createBrowserHistory(BrowserRouter)

const store =  createStore(Reducer, applyMiddleware(thunk));
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter history={history}>
      <HelpCenter />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);




