import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { helpActions } from "../_action/help.actions";
import '../style/HelpCenter.scss';
import Header from './Header/Header'
import FAQLFAQList from './Section/FAQList';
import help from '../reducer/help';
import FAQDetail from './Section/FAQDetail'
// import { createBrowserHistory } from 'history'

// const history = createBrowserHistory();
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link, NavLink,
  withRouter
} from "react-router-dom";
import { Layout, Row, Col } from 'antd';
import Section from './Section/Section'

const { Content } = Layout;
export class HelpCenter extends Component {

  componentDidMount() {
    this.props.loadAllFAQs()
  }
  render() {
    return (

      <Layout>
        <div className="help-center">
          <div className="help-header">
            <Header />
          </div>
          <Content className='container-wrapper'>
            <div className="help-section container">
              <Section />
            </div>
          </Content>
          {/* <div className="help-footer">
          </div> */}
        </div>
      </Layout>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  loadAllFAQs: () => dispatch(helpActions.loadAllFAQs())
})

const mapStateToProps = state => ({
  FAQData: state.help
})

export default connect(mapStateToProps, mapDispatchToProps)(HelpCenter)