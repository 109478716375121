import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Avatar } from 'antd'
import _ from 'lodash'

export class Author extends Component {
  render() {
    return (
      <div className="FAQItem-about-author">
        <div style={{ marginRight: '10px' }}>
          <Avatar src="/images/author-avt1.jpg" />
        </div>
        <div className="FAQItem-about-article">
          <span>Tác giả: <p>Lan Hương</p> </span>
          <p>Cập nhập hơn một tuần trước</p>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {}
}
const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Author)
