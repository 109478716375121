import config from '../config';
function getAllFAQs() {
  return fetch(`${config.API_ROOT}/user/widget/public-faqs?appId=5ea28aa02cf4c9005fb5bb04`)
    .then(res => res.json())
    .catch(function (error) {
      console.log(error)
    })
}

function getFAQCategory() {
  return fetch(`${config.API_ROOT}/user/public-faq-categories?appId=5ea28aa02cf4c9005fb5bb04`)
    .then(res => res.json())
    .catch(function (error) {
      console.log(error)
    })
}


export const helpServices = {
  getAllFAQs,
  getFAQCategory
}