import React, { Component, Fragment } from 'react'

import { QuestionCircleOutlined } from '@ant-design/icons'
import { helpActions } from '../../_action/help.actions';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Layout, Row, Col, Avatar } from 'antd';
import { FAQDataContext } from '../../context';
import SearchResult from './SearchResult'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link, NavLink,
  withRouter
} from "react-router-dom";


const mapStateToProps = state => {
  return {
    FAQData: state.FAQData,
    FAQCategory: state.FAQCategory,
    searchQuery: state.searchQuery
  }
}

const mapDispatchToProps = dispatch => ({
  loadAllFAQs: () => dispatch(helpActions.loadAllFAQs()),
  getFAQCategory: () => dispatch(helpActions.getFAQCategory()),
})

export class FAQList extends Component {

  componentDidMount() {
    this.props.loadAllFAQs();
    this.props.getFAQCategory()
  }

  render() {
    const FAQCategotyData = _.groupBy(this.props.FAQData, item => item.faqCategoryId);
    const FAQCollections = Object.values(FAQCategotyData);
    const { FAQCategory } = this.props;
    if (!this.props.FAQData) {
      return null;
    }

    if (FAQCategory && FAQDataContext) {
      const FAQcontext = (id) => FAQDataContext.filter(item => item.faqCategoryId === id);
      return (
        <div className="faq-collection">
        {
          _.map(FAQCategory, collectionItem => {
            return (
              <Fragment>
                <Link className='faq-cat-item' to={`/collection/${collectionItem.id}`}>
                  <Row>
                    <Col span={24}>
                      <div className="collection-icon">
                        <img src={collectionItem.image} className='cat-image'/>
                      </div>
                      <div className="collection-content">
                        <div className="collection-title">
                          {collectionItem.title}
                        </div>
                        <div className="collection-description">
                          {
                            collectionItem.description ? collectionItem.description : null
                          }
                        </div>
                        <div className="collection-preview">
                          <ul className="writer-avt">
                            {
                              FAQcontext(collectionItem.id) && FAQcontext(collectionItem.id).length > 0 ?
                                _.map(FAQcontext(collectionItem.id)[0].authorAvt, (avt, index) => <li key={index}> <Avatar size={30} src={avt} style={{ marginRight: '5px' }} /> </li>)
                                : ''
                            }
                            <li>
                              <Avatar style={{ background: '#02B875', fontSize: '17px', fontWeight: '500' }}>+2</Avatar>
                            </li>
                          </ul>
                          <div className="about-writer">
                            <span style={{ display: 'flex', flexDirection: 'row', marginTop: '10px', marginBottom: '-10px' }}>
                              {
                                collectionItem.articleCount
                              }
                              <p style={{ marginLeft: '5px' }}> bài viết</p></span>
                            <div>
                              <span> Tác giả :  </span>
                              {
                                FAQcontext(collectionItem.id).length > 0 ?
                                  _.map(FAQcontext(collectionItem.id)[0].author, authorName => <p>{authorName} </p>)
                                  : ''
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Link>
              </Fragment>
            )
          }
          )
        }
      </div>
      )
    } else {
      return ('');
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FAQList)